      import React from 'react';
      import { Container } from 'react-bootstrap';
      import CampaignItem from './CampaignItem';
      import './CampaignSection.scss'
      import DroneImage from '../../static/V2/Untitled_Artwork 3.png';
      import suvImage from '../../static/V2/Untitled_Artwork 2.png';
      import NightVisionImage from '../../static/V2/Untitled_Artwork.png';
      import ThermalCamerasImage from '../../static/V2/Untitled_Artwork 1.png';


      const campaignsData = [
        {
          title: 'DRONE: EYES IN THE SKY',
          description: 'Acting as the eyes of Ukrainian military forces, drones help to defend their positions and effectively identify enemy positions without risking the lives of their military personnel.',
          commonItems: 'The most common drones: DJI Mavic 3 Pro, DJI Mavic 3T',
          imageUrl: DroneImage,
        },
        {
          title: 'SUV: VEHICLE FOR THE FRONT LINE',
          description: 'The role of transportation is pivotal in facilitating mobility, logistical support, and enhancing the combat effectiveness of military operations. It empowers Ukrainian armed forces to swiftly move, regroup, and respond to various scenarios with agility.',
          commonItems: 'The most common vehicles: Mitsubishi L200, Mitsubishi Pajero',
          imageUrl: suvImage,
        },
        {
          title: 'NIGHT VISION: SEE IN THE DARK',
          description: 'The benefits of night vision devices are extensive, providing the capability to see in the dark and execute intricate tasks, from complex combat operations to evacuations conducted in total darkness.',
          commonItems: 'The most common night vision devices: AN/PVS-14 series, AN/PVS-7 series',
          imageUrl: NightVisionImage,
        },
        {
          title: 'THERMAL CAMERA: THERMAL ENERGY FOR USE',
          description: 'Thermal imaging improves the visibility of objects in a low-visibility environment. It uses infrared radiation and thermal energy to interpret information about the heat from objects and helps Ukrainian defenders to reduce that enemy heat on their land.',
          commonItems: 'The most common thermal imaging cameras: AN/PAS-13, Zeiss DTI 3/35',
          imageUrl: ThermalCamerasImage,
        },
      ]

      const CampaignsSection = () => {
        return (
          <Container className="campaigns-section">
            <h2 className='campaigns-section-title'>CURRENT CAMPAIGNS</h2>
            {campaignsData.map((campaign, index) => (
              <CampaignItem key={index} index={index} {...campaign} />
            ))}
          </Container>
        );
      };

      export default CampaignsSection;
