import React from 'react';
import Header from './Header';
import Statistics from './Statistics';
import CampaignsSection from './CampaignsSection';
import AboutUs from './AboutUs';
import NewFooter from './NewFooter';
import FollowUs from './FollowUs';
import ImageSlider from './ImageSlider';

import SliderImage_2 from '../../static/V2/carusel/Slider_2.jpg';
import SliderImage_3 from '../../static/V2/carusel/Slider_3.jpg';
import SliderImage_4 from '../../static/V2/carusel/Slider_4.jpg';
import SliderImage_5 from '../../static/V2/carusel/Slider_5.jpg';
import SliderImage_7 from '../../static/V2/carusel/Slider_7.jpg';
import SliderImage_8 from '../../static/V2/carusel/Slider_8.jpg';
import SliderImage_9 from '../../static/V2/carusel/Slider_9.jpg';
import SliderImage_10 from '../../static/V2/carusel/Slider_10.jpg';
import SliderImage_11 from '../../static/V2/carusel/Slider_11.jpg';
import SliderImage_12 from '../../static/V2/carusel/Slider_12.jpg';
import SliderImage_13 from '../../static/V2/carusel/Slider_13.jpg';
import SliderImage_15 from '../../static/V2/carusel/Slider_15.jpg';
import SliderImage_17 from '../../static/V2/carusel/Slider_17.jpg';
import SliderImage_18 from '../../static/V2/carusel/Slider_18.jpg';

const images = [
  SliderImage_2,
  SliderImage_3,
  SliderImage_4,
  SliderImage_5,
  SliderImage_7,
  SliderImage_8,
  SliderImage_9,
  SliderImage_10,
  SliderImage_11,
  SliderImage_12,
  SliderImage_13,
  SliderImage_15,
  SliderImage_17,
  SliderImage_18,
];

const WrapV2 = () => {
  return (
    <>
      <Header />
      <Statistics />
      <CampaignsSection />
      <AboutUs />
      <ImageSlider images={images} />
      <FollowUs />
      <NewFooter />
    </>
  );
};

export default WrapV2;
