import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './FollowUs.scss';

const FollowUs = () => {
  return (
    <Container className="follow-us text-center">
      <Row>
        <Col>
          <img
            src={require(`../../static/logo.png`)}
            alt="Logo"

          />

          <h2>Follow us on Facebook!</h2>
          <p>Check out the most recent news, upcoming events, and updates on fundraising efforts.</p>
          <Button variant="primary" href="https://www.facebook.com/groups/pco4ua" target="_blank" style={{ fontWeight: 'bold' }}>
            FACEBOOK
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FollowUs;
