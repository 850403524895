import React, { useState } from 'react';
import Slider from 'react-slick';
import './ImageSlider.scss';
import ImageModal from './ImageModal';

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-prev"
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-next"
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

const ImageSlider = ({ images }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 6,
    adaptiveHeight: true,
    slide: 'div',
    autoplaySpeed: 5000,
    customPaging: (i) => <div className="slick-dot-line"></div>,
    dotsClass: 'slick-dots slick-thumb',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="slick-slider-component">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slick-slide-container">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="slick-slide-image"
              onClick={() => openModal(image)}
            />
          </div>
        ))}
      </Slider>
      <ImageModal
        isOpen={modalOpen}
        onClose={closeModal}
        image={selectedImage}
      />
    </div>
  );
};

export default ImageSlider;
