import React, {useState} from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const CampaignDetails = ({ title, description, commonItems  }) => {
  const [showCommonItems, setShowCommonItems] = useState(false);

  const toggleCommonItems = () => {
    setShowCommonItems(!showCommonItems);
  };

  return (
    <Col md={10} className='campaign-details' >
      <div >
        <h3>{title}</h3>
        <p>{description}</p>
        {showCommonItems && <p>{commonItems}</p>}
        <Button variant="link" onClick={toggleCommonItems}>
          {showCommonItems ? 'See less details' : 'See more details'}
        </Button>
      </div>
    </Col>
  );
};

const CampaignItem = ({ title, description, commonItems, imageUrl, index }) => {


  return (
    <Row className={`campaign-item`}>
       <Col md={2} className="campaign-image">
          <img src={imageUrl} alt="Logo"/>
        </Col>
        <CampaignDetails title={title} description={description} commonItems={commonItems}  />
    </Row>
  );
};

export default CampaignItem;
