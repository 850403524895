import React from 'react';
import './ImageModal.scss';

const ImageModal = ({ isOpen, onClose, image }) => {
  const handleCloseModal = (event) => {
    if (event.target.classList.contains('modal')) {
      onClose();
    }
  };

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`} onClick={handleCloseModal}>
      <span className="close" onClick={onClose}>
        &times;
      </span>
      <img className="modal-content" src={image} alt="Modal" />
    </div>
  );
};

export default ImageModal;
