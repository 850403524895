import React from 'react';
import "./Statistics.scss";

const StatisticsCard = ({ title, value }) => {
  return (
    <div className="text-center statistics-card">
      <div>
        <p className="card-title">{value}</p>
        <p className="card-text">{title}</p>
      </div>
    </div>
  );
};

export default StatisticsCard;
