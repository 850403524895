import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUs.scss';

const AboutUs = () => {
  return (
    <Container className="about-us">
      <Row className="justify-content-center">
        <Col md={8}>
          <h2 className="about-us-title">ABOUT US</h2>
          <p className="about-us-text">
            Get to know us! We're a group of friends and family members scattered worldwide, primarily
            rooted in Colorado. Our network includes partners and supporters in Ukraine, Poland, and
            along the East and West Coasts of the US.
          </p>
          <p className="about-us-text">
            With 0% administrative overhead, our volunteer team is dedicated to addressing the
            specific needs of individuals we know personally.
          </p>
          <p className="about-us-text">
            United by a shared goal of helping Ukraine, every team member has personal connections
            in the country, relaying the needs of friends and relatives. Your support goes directly to
            those just two handshakes away.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
