import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Footer.scss';
import { FaFacebookF } from 'react-icons/fa';
import useAnalyticsEventTracker from './../useAnalyticsEventTracker';

const NewFooter = () => {
  const gaEventTracker = useAnalyticsEventTracker('DonateButton');
  const hostedButtonId = '6KZD4YP6NB4XC';
  const handleSubmit = (event) => {
    event.preventDefault();
    gaEventTracker('Donate');
    window.location.href = `https://www.paypal.com/donate?hosted_button_id=${hostedButtonId}`;
  };

  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-between">
          <Col sm={12} md={4} className="footer-contact">
            <div>
              <h5>Contact Us</h5>
              <p>
                <a href="tel:+17208080300">+1 720-808-0300</a>
              </p>
              <p>
                <a href="mailto:info@co4ua.org">info@co4ua.org</a>
              </p>

              <form onSubmit={handleSubmit} target="_top">
                <Button
                  type="submit"
                  style={{
                    backgroundColor: '#FFDC00',
                    border: '1px',
                    fontWeight: 'bold',
                  }}
                >
                  DONATE
                </Button>
              </form>
            </div>
            <div className="line"></div>
          </Col>

          <Col sm={12} md={5} className="footer-social">
            <h5>Follow us</h5>
            <a
              href="https://www.facebook.com/groups/pco4ua"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF style={{ color: '#0091B0', fontSize: '2em' }} />
            </a>
          </Col>
          <Col sm={12} md={3} className="footer-logo">
            <img
              src={require(`../../static/logo.png`)}
              alt="Coloradans for Ukraine logo"
            />
            <div>
              <p>© 2022 Charity Foundation “Coloradans for Ukraine”</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default NewFooter;
