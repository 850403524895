import './scss/style.scss';
import ReactGA from 'react-ga4';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import WrapV2 from './components/V2/WrapV2';
import Shop from './components/Shop/Shop';
import { ThemeProvider } from 'react-bootstrap';
import './scss/root.scss'


const TRACKING_ID = "G-B5X1ZZ6KJS";

ReactGA.initialize(TRACKING_ID);

export default function App() {
  return (
    <ThemeProvider >
      <Router>
          <Routes>
            <Route path='/shop' element={<Shop/>} />
            <Route path='/' element={<WrapV2 />} />
            <Route path='/donate' element={<WrapV2 />} />
          </Routes>
      </Router>
    </ThemeProvider>
  );
}